export default {
  allQuestions(state) {
    return state.questions;
  },
  allQuestionsByOpportunitySfid(state, getters) {
    return state.questions.filter((question) => {
      return question.opportunity === getters.opportunity.sfid;
    });
  },
  questionByIndex(state, getters) {
    return (index) => {
      return state.questions.filter((question) => {
        return (
          question.opportunity === getters.opportunity.sfid &&
          index === question.sort
        );
      })[0];
    };
  },
  questionsByIds(state, getters) {
    return (indexes) => {
      if (!indexes) {
        return [];
      }
      return state.questions.filter((question) => {
        return (
          question.opportunity === getters.opportunitySfid &&
          indexes.includes(question.sort)
        );
      });
    };
  },
  getAnswer(state, getters) {
    return (index) => {
      if (!state.questions[index]) {
        return '';
      }
      return state.questions.filter((question) => {
        return question.opportunity === getters.opportunity.sfid;
      })[index].answer;
    };
  },
  questionsNotAnswered() {
    return (questions) => {
      return questions.filter((question) => {
        return question.answer === '' || !question.answer;
      }).length;
    };
  },
  ifQuestionsAnswered(state, getters) {
    return (
      getters.questionsNotAnswered(getters.allQuestionsByOpportunitySfid) === 0
    );
  },
  ifQuestionsAnsweredByIndexes(state, getters) {
    return (indexes) => {
      return (
        getters.questionsNotAnswered(getters.questionsByIds(indexes)) === 0
      );
    };
  },
  getFlowsByOpportunuity(state, getters) {
    return state.flows.filter((flow) => {
      return flow.opportunity === getters.opportunity.sfid;
    });
  },
  flowsByOpportunitySfid(state, getters) {
    return state.flows.filter((flow) => {
      return flow.opportunity === getters.opportunity.sfid;
    });
  },
  opportunitySfid(state) {
    if (!state.opportunity.sfid) {
      return '';
    }
    return state.opportunity.sfid;
  },
  opportunity(state) {
    return state.opportunity;
  },
};
