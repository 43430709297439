<template>
  <b-row>
    <b-col
      cols="12"
      v-for="field in addressFieldsKeys"
      :lg="field == 'street' || field == 'additionalinfo' ? '12' : '4'"
      :key="field"
    >
      <strong v-if="field != 'country_code'" class="my-3">
        {{
          $t(`inputs.customer.${field == 'postal_code' ? 'post-code' : field}`)
        }}:
      </strong>
      <br />
      <b-form-input
        v-if="field != 'country_code' && field != 'country'"
        :placeholder="
          $t(`inputs.customer.${field == 'postal_code' ? 'post-code' : field}`)
        "
        v-model="addressData[field]"
        class="my-2"
      ></b-form-input>
      <b-form-select
        v-if="field == 'country'"
        v-model="addressData['country_code']"
        @input="countryCodeChanged"
        :options="countries"
        class="my-2"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  data: () => ({
    address: [],
  }),
  props: {
    addressFieldsKeys: {
      type: Array,
      required: false,
    },
    addressData: { type: Object, required: false },
  },
  computed: {},
  methods: {
    handleInput(e) {
      this.$emit('input', this.address);
    },
  },
  created() {
    this.address = this.addressData;
  },
};
</script>

<style></style>
