import Vue from 'vue';
import Vuex from 'vuex';

import { storeCommon, storeCommonInit } from '@gid/vue-common/store';
import planning_module from './planning.module';
import quote_module from './quote';

Vue.use(Vuex);

const defaultTitle = 'GID Customer Portal';
const store = new Vuex.Store({
  modules: {
    ...storeCommon.modules,
    planning_module,
    quotes: quote_module,
  },
  plugins: [...storeCommon.plugins],
  state: {
    title: defaultTitle,
  },
  mutations: {
    title(state, title) {
      if (title) {
        state.title = title;
        document.title = `${defaultTitle} - ${title}`;
      } else {
        state.title = defaultTitle;
        document.title = defaultTitle;
      }
    },
  },
});

storeCommonInit(store);

export default store;
