import { JobStatusEnum } from '@gid/models';

export default {
  alternativeToBeDisplayed(state) {
    return state.alternativeJobs.filter(
      (j) => j?.job?.status == JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
    );
  },
  jobWithAlternatives(state) {
    return state.jobWithAlternatives;
  },
};
