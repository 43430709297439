<template>
  <div class="bg-light border-bottom py-3 text-center">
    <div v-if="isChargemondoOpp" class="text-center pl-2 pr-2">
      <img
        src="https://gidpublicdata.s3.amazonaws.com/brand/0010800002uJJrmAAG/upload/assets/forApps"
        alt="Chargemondo"
        class="gid-logo my-2"
      />
    </div>
    <div v-else-if="isBaywaOpp">
      <img
        src="https://gidpublicdata.s3.amazonaws.com/brand/0010800002uKMYKAA4/upload/assets/forApps"
        alt="Baywa"
        class="gid-logo my-2"
      />
    </div>
    <div v-else-if="isBaywaOppUnique" class="text-center pl-2 pr-2">
      <img src="@/assets/gid-logo.svg" alt="GID" class="gid-logo-cooperation" />
      <h5
        class="text-muted mw-90 d-sm-inline my-2 my-sm-0 mx-0 mx-sm-3 align-middle"
      >
        {{ $t('cooperation-logo-text') }}
      </h5>
      <img
        src="https://gidpublicdata.s3.amazonaws.com/brand/0010800002uKMYKAA4/upload/assets/forApps"
        alt="Baywa"
        class="gid-logo-cooperation mx-auto mw-100 d-sm-inline"
      />
    </div>
    <div v-else-if="isGidLogo && !isLoading" class="text-center pl-2 pr-2">
      <img src="@/assets/gid-logo.svg" alt="GID" class="gid-logo my-3" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    chargemondoOpp: Boolean,
    baywaOpp: Boolean,
    baywaOppUnique: Boolean,
    isLoading: Boolean,
  },
  computed: {
    isChargemondoOpp() {
      return this.chargemondoOpp && !this.baywaOppUnique;
    },
    isBaywaOpp() {
      return this.baywaOpp && !this.chargemondoOpp && !this.baywaOppUnique;
    },
    isBaywaOppUnique() {
      return this.baywaOppUnique;
    },
    isGidLogo() {
      return !this.baywaOpp && !this.chargemondoOpp && !this.baywaOppUnique;
    },
  },
};
</script>

<style lang="scss">
.gid-logo {
  height: 2.4rem;
}
.gid-logo-cooperation {
  height: 2rem;
}
</style>
