<template>
  <div>
    <b-alert :show="!loading && errors.length" variant="danger">
      <p v-for="error in errors" :key="error.message">{{ error.message }}</p>
    </b-alert>
    <h1
      v-if="!loading && !errors.length"
      class="my-5 display-4 text-center text-muted"
    >
      Order Cancelled
    </h1>
  </div>
</template>
<script>
import axios from 'axios';
import { JobStatusEnum } from '@gid/models';
export default {
  props: {
    order_id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    errors: [],
  }),
  async created() {
    try {
      await axios.post('/api/customer/orders/set-status', {
        input: {},
        job_id: this.order_id,
        new_status: JobStatusEnum.CANCELLED,
        preview: false,
        target: [],
      });
      this.loading = false;
    } catch (e) {
      this.errors.push(e);
      this.loading = false;
    }
  },
};
</script>
