import { GET_JOB, ADD_TO_ALTERNATIVES, SET_ALTERNATIVE_IDS } from './types';
import axios from 'axios';

export default {
  [GET_JOB]: async ({ commit }, { id }) => {
    const response = await axios.get(`/api/customer/orders/${id}`);
    commit(GET_JOB, response.data);
    if (response.data?.properties?.alternative_jobs?.length) {
      const alternativeIds = response.data?.properties?.alternative_jobs;
      commit(SET_ALTERNATIVE_IDS, alternativeIds);
      await Promise.all(
        alternativeIds?.map(async (id) => {
          const res = await axios.get(`/api/customer/orders/${id}`);
          commit(ADD_TO_ALTERNATIVES, res.data);
        }),
      );
    }
  },
  [ADD_TO_ALTERNATIVES]: async ({ commit }, { id }) => {
    const response = await axios.get(`/api/customer/orders/${id}`);
    commit(ADD_TO_ALTERNATIVES, response.data);
  },
};
