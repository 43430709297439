import moment from '@gid/vue-common/app-settings/moment';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';
import * as Sentry from '@sentry/browser';

import App from './App.vue';

import messages from './i18n/messages';
import router, { initRouter } from './router';
import store from './store';
import {
  initVeeValidateRulesWithI18n,
  setVeeValidateLocale,
} from './app/components';
import './app/filters';

import {
  PURGE_AUTH,
  STORE_USER_DATA,
} from '@gid/vue-common/store/auth.module/types';
import { e2e } from '@gid/vue-common/app-settings/customDirectives';
import { setupGoogleAnalytics } from '@gid/vue-common/app-settings/googleAnalytics';
import '@gid/vue-common/app-settings/registerServiceWorker';
import { getEnv } from '@gid/vue-common/utils';

import '@/assets/scss/styles.scss';

Vue.directive('e2e', e2e);

Vue.use(VueI18n);
Vue.use(VueMoment, { moment });

const defaultLocale = store.getters.locale;
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages,
});
moment.locale(defaultLocale);
setVeeValidateLocale(defaultLocale);
initVeeValidateRulesWithI18n(i18n);

initRouter(router, i18n, store);

store.subscribe(({ type }) => {
  if (type === PURGE_AUTH) {
    // Go to login page on logout
    router.push({ name: 'login' });
  } else if (type === STORE_USER_DATA) {
    // Set locales in used components
    const locale = store.getters.locale;
    i18n.locale = locale;
    moment.locale(locale);
    setVeeValidateLocale(locale);
  }
});

setupGoogleAnalytics(router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

if (getEnv('NODE_ENV') == 'production') {
  Sentry.init({
    dsn: 'https://ecba967fa94243d09b55fa0fee90855f@sentry.io/1499238',
    environment: getEnv('GID_ENV') ?? getEnv('VUE_APP_ENV'),
    release: getEnv('VUE_APP_SOURCE_VERSION'),
    integrations: [new Sentry.Integrations.Vue({ Vue })],
  });
}
