<template>
  <b-container fluid>
    <header-logo
      :chargemondoOpp="isChargemondoOpp"
      :baywaOpp="isBaywaOpp"
      :baywaOppUnique="isBaywaOppUnique"
      :isLoading="loading"
    />
    <div v-if="order">
      <b-row class="border-bottom">
        <b-col cols="12" class="text-center">
          <i18n
            path="order-details.title"
            tag="h1"
            class="h2 font-weight-light mt-3 mb-4"
          >
            <strong slot="job">{{ order.job.name }}</strong>
            <strong slot="opportunity">{{ order.opportunity.name }}</strong>
            <strong slot="brand">{{ order.brand.name }}</strong>
          </i18n>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-center">
        <div class="d-inline-block text-center my-5">
          <strong style="font-size: 1.3rem">{{ title }}</strong>
          <b-alert class="mt-2" variant="success" show>
            {{ description }}
          </b-alert>
        </div>
      </div>
    </div>
    <not-found v-else-if="order === false" />
    <b-spinner v-else variant="secondary" class="my-3"></b-spinner>
  </b-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import NotFound from '@/views/NotFound.vue';
import HeaderLogo from '@/components/HeaderLogo.vue';

import {
  CONTEXT_CREATE,
  CONTEXT_DESTROY,
} from '@gid/vue-common/store/validation.module/types';
import {
  CHARGEMONDO_OPP,
  BAYWA_DIENSTWAGENFAHRER_OPP,
  BAYWA_MEHRPLATZ_OPP,
} from './Quote.vue';

const VALIDATION_CONTEXT_PREFIX = 'order';

export default {
  components: {
    HeaderLogo,
    NotFound,
  },
  props: {
    id: String,
    title: String,
    description: String,
  },
  data() {
    return {
      order: null,
      loading: true,
    };
  },
  computed: {
    validationContext() {
      return `${VALIDATION_CONTEXT_PREFIX}-${this.order.job.sfid}`;
    },
    isChargemondoOpp() {
      return this.order?.opportunity.sfid == CHARGEMONDO_OPP;
    },
    isBaywaOpp() {
      return BAYWA_DIENSTWAGENFAHRER_OPP == this.order?.opportunity.sfid;
    },
    isBaywaOppUnique() {
      return BAYWA_MEHRPLATZ_OPP == this.order?.opportunity.sfid;
    },
  },
  watch: {
    id() {
      this.loadData();
    },
    order(newValue, oldValue) {
      if (oldValue) {
        this.destroyValidationContext(oldValue.job.sfid);
      }
      if (newValue) {
        this.createValidationContext(newValue.job.sfid);
      }
    },
  },
  created() {
    this.loadData();
  },
  destroyed() {
    if (this.order) {
      this.destroyValidationContext(this.order.job.sfid);
    }
  },
  methods: {
    loadData() {
      axios
        .get(`/api/customer/orders/${this.id}`)
        .then((response) => {
          this.order = response.data;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.order = false;
          }
        });
    },
    createValidationContext(context) {
      this.$store.dispatch(`validation/${CONTEXT_CREATE}`, {
        context: `${VALIDATION_CONTEXT_PREFIX}-${context}`,
      });
    },
    destroyValidationContext(context) {
      this.$store.dispatch(`validation/${CONTEXT_DESTROY}`, {
        context: `${VALIDATION_CONTEXT_PREFIX}-${context}`,
      });
    },
  },
};
</script>
