<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>

    <offline />
  </div>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue';
import NoAuth from '@/views/layouts/NoAuth.vue';
import Blank from '@/views/layouts/Blank.vue';

export default {
  components: {
    'auth-layout': Auth,
    'no-auth-layout': NoAuth,
    'blank-layout': Blank,
  },
  computed: {
    layout() {
      const matchedRoutes = this.$route.matched.slice(0).reverse();
      const routeWithLayout = matchedRoutes.find((route) => route.meta.layout);
      return (
        (routeWithLayout ? routeWithLayout.meta.layout : 'auth') + '-layout'
      );
    },
  },
};
</script>

<style lang="scss">
.gid-footer-note {
  max-width: 56rem;
}
</style>
