<template>
  <header>
    <b-navbar class="border-bottom" :variant="navbarVariant">
      <b-navbar-nav
        class="py-3 justify-content-center align-items-center list-unstyled w-100"
        :class="{ 'justify-content-between': isAuthLayout }"
      >
        <b-nav-item to="/">
          <b-navbar-brand>
            <img src="@/assets/gid-logo.svg" alt="GID" class="gid-logo my-2" />
          </b-navbar-brand>
          <b-badge
            v-if="environment != 'production'"
            class="ml-2"
            variant="warning"
          >
            {{ environment }}
          </b-badge>
        </b-nav-item>
        <template v-if="isAuthLayout">
          <li>
            <b-button variant="primary" @click="logout">Logout</b-button>
          </li>
        </template>
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { PURGE_AUTH } from '@gid/vue-common/store/auth.module/types';

export default {
  props: {
    isAuthLayout: Boolean,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'environment']),
    navbarVariant() {
      if (this.$store.state.auth.user?.profiles?.includes('readonly')) {
        return 'readonly';
      } else if (this.environment === 'production') {
        return 'light';
      } else {
        return 'staging';
      }
    },
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
    },
  },
};
</script>
