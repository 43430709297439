<template>
  <div>
    <ChatView />
  </div>
</template>

<script>
import ChatView from '@gid/vue-common/components/chat-view/chat.vue';

export default {
  components: {
    ChatView,
  },
};
</script>

<style scoped>
/* Add any styles specific to this view if needed */
</style>
