<template>
  <div>
    <slot />
    <footer-info />
  </div>
</template>

<script>
import FooterInfo from '@/components/FooterInfo.vue';

export default {
  components: {
    FooterInfo,
  },
};
</script>
