import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Chat from '@/views/Chat.vue';
import Order from '@/views/Order.vue';
import OrderAccess from '@/views/OrderAccess.vue';
import NotFound from '@/views/NotFound.vue';
import OrderCancelled from '@/views/OrderCancelled.vue';
import OrderSuccess from '@/views/OrderSuccess.vue';
import UploadPlanning from '@/views/wallbox/UploadPlanning.vue';
import QuoteAccept from '@/views/QuoteAccept.vue';
import AtWork from '@/views/AtWork.vue';
import WaitingPrepayment from '@/views/WaitingPrepayment.vue';
import QuoteReopen from '@/views/QuoteReopen.vue';

import RequestPasswordReset from '@gid/vue-common/views/RequestPasswordReset.vue';
import ChangePassword from '@gid/vue-common/views/ChangePassword.vue';

Vue.use(Router);

/* Routes definition specification
 *
 * For each route access is defined via the meta setting
 * `auth`, which could contain `contactProfiles`, `accountProfiles` and `accountStatuses`.
 * If `auth` is present, the user must be authenticated.
 * `contactProfiles` recognizes the following values:
 * - true: requires authenticated user without contact profile restrictions
 * - array of strings: requires authenticated with one of the specified contact profiles
 */

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'no-auth',
      },
      component: Login,
    },
    {
      path: '/chat-view/:assistant_id',
      name: 'Chat',
      meta: {
        layout: 'no-auth',
        allowGuests: true,
      },
      component: Chat,
    },
    {
      path: '/request-password-reset',
      name: 'request-password-reset',
      component: RequestPasswordReset,
      meta: {
        layout: 'no-auth',
      },
      props: {
        role: 'customer',
      },
    },
    {
      path: '/change-password',
      name: 'change-password',
      meta: {
        layout: 'no-auth',
      },
      component: ChangePassword,
      props: {
        role: 'customer',
      },
    },
    {
      path: '/order-access/:id',
      name: 'order-access',
      meta: {
        layout: 'blank',
        title: { i18nKey: 'order-access' },
      },
      component: OrderAccess,
      props: true,
    },
    {
      path: '/order-access/cancel/:id',
      name: 'order-access-cancel',
      meta: {
        layout: 'no-auth',
        title: { i18nKey: 'order-access-cancel' },
      },
      component: OrderAccess,
      props: true,
    },
    {
      path: '/order/cancelled',
      name: 'order-cancelled',
      meta: {
        layout: 'no-auth',
      },
      component: OrderCancelled,
      props: true,
    },
    {
      path: '/order-success/:id',
      name: 'order-success',
      meta: {
        layout: 'blank',
        title: { i18nKey: 'order' },
      },
      component: OrderSuccess,
      props: (route) => ({
        id: route.params.id,
        title: route.query.title,
        description: route.query.description,
      }),
    },
    {
      path: '/order/:id',
      name: 'order',
      meta: {
        layout: 'blank',
        title: { i18nKey: 'order' },
      },
      component: Order,
      props: true,
    },
    {
      path: '/upload-planning/:id',
      name: 'upload-planing',
      component: UploadPlanning,
      props: true,
    },
    {
      path: '/quote-accept/:id',
      name: 'quote-accept',
      meta: { layout: 'blank' },
      component: QuoteAccept,
      props: true,
    },
    {
      path: '/quote-reopen/:id',
      name: 'quote-reopen',
      component: QuoteReopen,
      props: true,
    },
    {
      path: '/at-work',
      name: 'at-work',
      component: AtWork,
    },
    {
      path: '/waiting-prepayment/:orderId',
      name: 'waiting-prepayment',
      component: WaitingPrepayment,
      props: true,
    },
    {
      path: '*',
      meta: { layout: 'no-auth' },
      component: NotFound,
    },
  ],
});

export function initRouter(router, i18n, store) {
  router.beforeEach((to, from, next) => {
    const matchedRoutes = to.matched.slice(0).reverse();
    const allowGuests = matchedRoutes.some((route) => !route.meta?.auth);
    let titleMeta = matchedRoutes.find((route) => route.meta?.title);
    if (titleMeta) {
      const title = titleMeta.meta.title;
      store.commit('title', title.i18nKey ? i18n.t(title.i18nKey) : title);
    } else {
      store.commit('title', null);
    }

    if (
      store.getters.isAuthenticated &&
      (to.name === 'request-password-reset' || to.name === 'change-password')
    ) {
      next({ name: 'home' });
    } else if (
      !store.getters.isAuthenticated &&
      to.name != 'login' &&
      !allowGuests
    ) {
      next({ name: 'login' });
    } else {
      next();
    }
  });
}
