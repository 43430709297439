<template>
  <div>
    <b-alert show variant="success text-center" class="m-5" fluid>
      <p>Sie haben das Angebot angenommen und Vorauszahlung gewählt.</p>
      <p>
        Um Ihre Bestellung zu bestätigen, müssen Sie die Zahlung abschliessen.
      </p>
    </b-alert>

    <div class="m-5 text-center" v-if="!loading">
      <b-button variant="primary" :href="paymentUrl">
        {{ $t('waiting-prepayment.to-payment') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { appUrl } from '@gid/vue-common/app-settings/appUrls';
import axios from 'axios';

export default {
  props: {
    orderId: String,
  },
  data() {
    return {
      loading: true,
      invoices: [],
    };
  },
  computed: {
    paymentUrl() {
      let url = appUrl('payment', `/payment/stripe/for-job/${this.orderId}`);
      if (this.invoices.length == 1) {
        // TODO: here we speculate that if there is one invoice created per job
        // we should use it for prepayment which might not be true
        url += `?documentId=${this.invoices[0].invoice.id}`;
      }
      return url;
    },
  },
  async created() {
    try {
      const {
        data: { data: invoices },
      } = await axios.get(`/api/customer/invoices/for-job/${this.orderId}`);
      this.invoices = invoices;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  },
};
</script>
