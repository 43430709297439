<template>
  <b-row class="border-bottom py-3 align-items-center">
    <b-col cols="12" lg="4" class="text-muted mb-2 mb-lg-0">
      {{ autoTitle }}:
    </b-col>
    <b-col cols="12" lg="8">
      <slot />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    inputName: String,
    title: String,
  },
  computed: {
    autoTitle() {
      const titleTerm = this.title ? this.title : this.inputName;
      return this.$t(`inputs.headings.${titleTerm}`);
    },
  },
};
</script>
