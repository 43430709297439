<template>
  <div>
    <b-alert
      v-if="initialLoading"
      show
      variant="secondary"
      class="m-5 text-center"
      fluid
    >
      <p>{{ $t('reactivate-quote.reactivate-loading-quote-description') }}</p>
      <b-spinner></b-spinner>
    </b-alert>
    <b-alert
      v-else
      show
      :variant="allowReactivation ? 'success' : 'danger'"
      class="m-5 text-center"
      fluid
    >
      <p v-if="allowReactivation">
        {{ $t('reactivate-quote.reactivate-quote-description') }}
      </p>
      <p v-else>
        {{ $t('reactivate-quote.reactivate-forbidden-quote-description') }}
      </p>
      <b-button
        v-e2e:reactivateProposal
        :disabled="loading || !allowReactivation"
        variant="primary"
        size="lg"
        class="mr-2 mt-2 gid-submit-button"
        @click="reactivate"
      >
        <b-spinner v-if="loading" class="gid-spinner--button mr-2"></b-spinner>
        {{ $t('reactivate-quote.reactivate-quote') }}
      </b-button>
    </b-alert>
  </div>
</template>
<script>
import { JobStatusEnum } from '@gid/models';
import axios from 'axios';

export default {
  props: {
    id: String,
  },
  data() {
    return {
      error: null,
      loading: false,
      allowReactivation: false,
      initialLoading: true,
    };
  },
  async created() {
    try {
      const res = await axios.get(
        `/jobs-api/jobs/${this.id}/check-reactivation`,
      );
      this.allowReactivation = res.data;
    } catch (e) {
      this.error = e;
    } finally {
      this.initialLoading = false;
    }
  },
  methods: {
    async reactivate() {
      try {
        this.loading = true;
        this.error = null;
        await axios.post('/api/customer/orders/set-status', {
          preview: false,
          new_status: JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
          job_id: this.id,
        });
        this.$router.push({ name: 'quote-accept' });
      } catch (e) {
        this.error = e;
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
