<template>
  <div>
    <b-alert show variant="success" class="m-5 text-center" fluid>
      <p>Unsere Experten arbeiten derzeit an Ihrer Bestellung/Anfrage.</p>
      <p>Sie werden benachrichtigt, sobald es bereit ist.</p>
    </b-alert>
  </div>
</template>
<script>
export default {};
</script>
