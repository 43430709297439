
import { Model, Prop, Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  name: 'InfoCollapse',
  computed: mapGetters(['locale']),
})
export default class InfoCollapse extends Vue {
  @Model('change', { type: Boolean }) readonly extended!: boolean;
}
