<template>
  <div>
    <b-container fluid style="max-width: 720px" v-if="!approved">
      <template v-if="flow_step == -1 && flow == ''">
        <b-row class="mt-3 mb-5">
          <b-col sm class="pb-2">
            <h4>
              Um Ihnen ein persönliches Angebot für Ihre Ladestation erstellen
              zu können, benötigen wir noch einige Informationen
            </h4>
            <div>
              Lassen Sie einen unserer erfahrenen Techniker zu einer
              Vor-Ort-Begehung zu Ihnen kommen, der dann direkt alle technischen
              Daten aufnimmt.
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-5">
          <b-col sm class="pb-2">
            <b-button
              variant="primary"
              size="lg"
              @click="
                flow = 'customerPlanning';
                ++flow_step;
              "
            >
              Los geht's
            </b-button>
          </b-col>
        </b-row>
      </template>
      <div
        v-for="(flowStepProperties, index) in flows"
        :key="flowStepProperties.questions[0]"
      >
        <planning-card
          v-if="flow_step == index"
          @nextFlowStep="nextFlowStep()"
          @previousFlowStep="previousFlowStep()"
          :title="flowStepProperties.headline"
          :flow_step="flow_step"
          :questionsIndexes="flowStepProperties.questions"
        />
      </div>

      <b-row class="my-3" v-if="allowedToSend">
        <b-col lg="4" offset-sm="4" class="send-button-container">
          <b-button
            variant="primary"
            :disabled="loading"
            class="customer-button px-5 py-1 send-button"
            @click="sendCustomerPlanning"
          >
            <b-spinner
              v-if="loading"
              class="gid-spinner--button mr-2 ml-n2"
            ></b-spinner>
            {{ $t('upload-planning.send') }}
          </b-button>
        </b-col>
      </b-row>
      <div v-else>
        <b-progress :value="progress_percent" class="mb-3"></b-progress>
      </div>
    </b-container>
    <b-container v-else>
      <b-alert show variant="success" class="my-5" fluid>
        <b-row>
          <b-col></b-col>
          <b-col lg="8">
            <h4 v-if="this.onsiteVisit && this.approvedOnSiteVisit">
              Vielen Dank für Ihre Anfrage, wir werden uns schnellstmöglich bei
              Ihnen zwecks einer Bestätigung melden.
            </h4>
            <h4 v-else-if="!this.onsiteVisit && this.approved">
              {{ $t('upload-planning.thank-you') }}
            </h4>
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-alert>
    </b-container>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import * as InputTypes from '@gid/vue-common/components/inputs/_types';
import PlanningCard from '../../components/PlanningCard.vue';
import {
  QUESTIONS_SET,
  FLOWS_SET,
  PLANNING_OPPORTUNITY_SET,
} from '../../store/planning.module/types';

const OPPORTUNITIES = Object.freeze({
  emlWallboxSingleFamilyHome: '0061p00000pDc3vAAC',
  emlWallboxMultiFamilyHome: '0061p00000oOJ3iAAG',
});

export default {
  components: {
    PlanningCard,
  },
  props: {
    id: String,
  },
  computed: {
    ...mapGetters(['locale']),
    sendInputs() {
      return this.$store.getters.jobInputsAllValues(this.order.job.sfid);
    },
    allowedToSend() {
      // return this.$store.getters.ifQuestionsAnswered;
      return this.flows.length - 1 == this.flow_step;
    },
    questions() {
      return this.$store.getters.allQuestionsByOpportunitySfid;
    },
    flows() {
      return this.$store.getters.flowsByOpportunitySfid;
    },
    progress_percent() {
      const notAnsweredQuestions = this.$store.getters.questionsNotAnswered(
        this.questions,
      );
      const totalQuestions = this.questions.length;
      const percent_answered =
        (1 - notAnsweredQuestions / totalQuestions) * 100;
      return percent_answered;
    },
    constructInputs() {
      let inputs = {};
      Object.keys(InputTypes)
        .filter((inputType) => {
          return (
            this.questions.filter((question) => {
              return question.target === InputTypes[inputType];
            }).length > 0
          );
        })
        .map((inputType) => {
          switch (InputTypes[inputType]) {
            case InputTypes.PLANNING_CUSTOMER:
              inputs[InputTypes[inputType]] = {
                files: [],
              };

              inputs[InputTypes[inputType]].files = this.questions
                .filter((question) => {
                  return (
                    question.target === InputTypes[inputType] &&
                    question.answer &&
                    question.answer.files
                  );
                })
                .reduce((acc, question) => {
                  return [...acc, ...question.answer.files];
                }, []);

              break;
            default:
              inputs[InputTypes[inputType]] = this.questions
                .filter((question) => {
                  return question.target === InputTypes[inputType];
                })
                .map((question) => {
                  return `${question.title}: ${question.answer}`;
                })
                .join('\n\n');
          }
        });

      if (
        Array.isArray(inputs[InputTypes.PLANNING_CUSTOMER].files) &&
        inputs[InputTypes.PLANNING_CUSTOMER].files.length === 0
      ) {
        delete inputs[InputTypes.PLANNING_CUSTOMER];
      }

      return inputs;
    },
  },
  data() {
    return {
      flow: '',
      flow_step: -1,
      onsiteVisit: false,
      options: {
        statuses: [],
        cancellations: [],
      },
      order: {},
      requestMixin: {},
      change: null,
      changeResult: null,
      approved: false,
      approvedOnSiteVisit: false,
      planningTitle: '',
      loading: false,
    };
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    nextFlowStep() {
      if (this.flow_step < this.flows.length) {
        this.flow_step++;
      }
    },
    previousFlowStep() {
      this.flow_step--;
    },
    async bookOnsiteVisit() {
      try {
        this.loading = true;
        this.onsiteVisit = true;
        await axios.post(`/api/customer/orders/set-status`, {
          new_status: 'new',
          preview: false,
          job_id: this.order.job.sfid,
          inputs: {
            [InputTypes.INTERNAL_COMMENTS]: 'customer books onsite visit',
          },
        });
        this.approved = true;
        this.approvedOnSiteVisit = true;
        this.transmission_successful = true;
      } catch (e) {
        throw new Error(e);
      }
      this.loading = false;
    },
    async sendCustomerPlanning() {
      try {
        this.loading = true;
        await axios.post(`/api/customer/orders/set-status`, {
          new_status: 'new',
          preview: false,
          job_id: this.order.job.sfid,
          inputs: this.constructInputs,
        });
        this.approved = true;
        this.transmission_successful = true;
      } catch (e) {
        throw new Error(e);
      }
      this.loading = false;
    },
    async loadData() {
      try {
        const response = await axios.get(`/api/customer/orders/${this.id}`);
        this.order = response.data;
        this.$store.dispatch(PLANNING_OPPORTUNITY_SET, {
          opportunity: this.order.opportunity,
        });
        this.$store.dispatch(FLOWS_SET, {
          flows: [
            {
              headline: 'Montageort',
              questions: [0, 1],
              step: 0,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              headline: 'Sicherheit',
              questions: [2],
              step: 1,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              headline: 'Stromanschluss',
              questions: [3, 4, 5],
              step: 2,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              headline: `Vom Hausanschlusses zum Installationsort`,
              questions: [6],
              step: 3,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              headline: 'Montageort',
              questions: [0, 1],
              step: 0,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              headline: 'Sicherheit',
              questions: [2],
              step: 1,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              headline: 'Stromanschluss',
              questions: [3, 4, 5],
              step: 2,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              headline: `Vom Hausanschlusses zum Installationsort`,
              questions: [6, 7],
              step: 3,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
          ],
        });
        this.$store.dispatch(QUESTIONS_SET, {
          questions: [
            {
              sort: 0,
              name: 'mounting-type',
              title: 'Welche Montage- bzw. Aufstellart bevorzugen Sie?',
              type: 'radio',
              multiple: false,
              options: ['Wandmontage*', 'Freistehend'],
              answer: '',
              explanation: `Bitte stellen Sie sicher, dass die Tragfähigkeit der entsprechenden Wand ausreichend ist und
                    wählen Sie die Option Wandmontage nur dann aus,
                    sofern keine Beschränkungen bei der Montagefähigkeit vorliegen, wie z.B. bei Passivhäusern.`,
              target: InputTypes.PROFESSIONAL_COMMENT,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              sort: 1,
              name: 'mounting-location-weather-depending',
              title: 'Ist der gewünschte Montageort Witterungen ausgesetzt?',
              type: 'radio',
              multiple: false,
              options: ['Ja', 'Nein'],
              answer: '',
              target: InputTypes.PROFESSIONAL_COMMENT,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              sort: 2,
              name: 'security',
              title:
                'Muss Ihre Ladestation vor Fremdnutzung geschützt werden? (Bei öffentlich zugänglichem Standort)',
              type: 'radio',
              multiple: false,
              options: ['Ja', 'Nein'],
              answer: '',
              target: InputTypes.PROFESSIONAL_COMMENT,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              sort: 3,
              name: 'electric-house-connection',
              title: 'Laden Sie ein Foto Ihres Hausanschlusses hoch:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              explanation:
                '*Als Hausanschluss bezeichnet man die Stromzuführung des Stromversorgers zu Ihrem Gebäude. Der Hausanschlusskasten befindet sich bei einer Erdeinführung in der Regel im Keller oder im Erdgeschoss, bei einer Einführung via Freileitung meist auf dem Dachboden.',
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              sort: 4,
              name: 'electric-pathway',
              title:
                'Laden Sie ein Foto vom Inneren Ihres Zählerschranks und Zählers hoch:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              sort: 5,
              name: 'electric-pathway',
              title:
                'Laden Sie ein Foto vom Äußeren Ihres Zählerschranks hoch:',
              type: 'file',
              multiple: true,
              max: 3,
              options: [],
              answer: '',
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },
            {
              sort: 6,
              name: 'electric-pathway',
              title:
                'Bitte laden Sie hierfür bis zu 3 Fotos vom Weg Ihres Hausanschlusses bis zum Zählerschrank und von dort bis zum Installationsort hoch:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              explanation: `In diesem Schritt geht es darum, den Weg zwischen Ihrem Stromanschluss und der Ladestation zu bestimmen.`,
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxSingleFamilyHome,
            },

            {
              sort: 0,
              name: 'mounting-type',
              title: 'Welche Montage- bzw. Aufstellart bevorzugen Sie?',
              type: 'radio',
              multiple: false,
              options: ['Wandmontage*', 'Freistehend'],
              answer: '',
              explanation: `Bitte stellen Sie sicher, dass die Tragfähigkeit der entsprechenden Wand ausreichend ist und
                    wählen Sie die Option Wandmontage nur dann aus,
                    sofern keine Beschränkungen bei der Montagefähigkeit vorliegen, wie z.B. bei Passivhäusern.`,
              target: InputTypes.PROFESSIONAL_COMMENT,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 1,
              name: 'mounting-location-weather-depending',
              title: 'Ist der gewünschte Montageort Witterungen ausgesetzt?',
              type: 'radio',
              multiple: false,
              options: ['Ja', 'Nein'],
              answer: '',
              target: InputTypes.PROFESSIONAL_COMMENT,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 2,
              name: 'security',
              title:
                'Muss Ihre Ladestation vor Fremdnutzung geschützt werden? (Bei öffentlich zugänglichem Standort)',
              type: 'radio',
              multiple: false,
              options: ['Ja', 'Nein'],
              answer: '',
              target: InputTypes.PROFESSIONAL_COMMENT,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 3,
              name: 'electric-house-connection',
              title: 'Laden Sie ein Foto Ihres Hausanschlusses hoch:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              explanation:
                '*Als Hausanschluss bezeichnet man die Stromzuführung des Stromversorgers zu Ihrem Gebäude. Der Hausanschlusskasten befindet sich bei einer Erdeinführung in der Regel im Keller oder im Erdgeschoss, bei einer Einführung via Freileitung meist auf dem Dachboden.',
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 4,
              name: 'electric-pathway',
              title:
                'Laden Sie ein Foto vom Inneren Ihres Zählerschranks und Zählers hoch:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 5,
              name: 'electric-pathway',
              title:
                'Laden Sie ein Foto vom Äußeren Ihres Zählerschranks hoch:',
              type: 'file',
              multiple: true,
              max: 3,
              options: [],
              answer: '',
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 6,
              name: 'electric-pathway',
              title:
                'Bitte laden Sie hierfür bis zu 3 Fotos vom Weg Ihres Hausanschlusses bis zum Zählerschrank und von dort bis zum Installationsort hoch:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              explanation: `In diesem Schritt geht es darum, den Weg zwischen Ihrem Stromanschluss und der Ladestation zu bestimmen.`,
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
            {
              sort: 7,
              name: 'plan-wallbox-installation',
              title:
                'Bitte fügen Sie nun einen Plan mit Maßen hinzu, der mindestens den Ort der Wallbox Installation bis zum Zählerschrank umfasst:',
              type: 'file',
              multiple: false,
              max: 1,
              options: [],
              answer: '',
              explanation: ``,
              target: InputTypes.PLANNING_CUSTOMER,
              opportunity: OPPORTUNITIES.emlWallboxMultiFamilyHome,
            },
          ],
        });
        this.fetchAvailableActions();
      } catch (e) {
        if (e.response && e.response.status == 404) {
          this.order = false;
        }
        throw new Error(e);
      }
    },
    async fetchAvailableActions() {
      try {
        const response = await axios.post(`/api/customer/orders/set-status`, {
          preview: true,
          job_id: this.order.job.sfid,
        });
        this.options.statuses = response.data.statuses.reduce((acc, cur) => {
          if (cur.allowed) {
            acc.push({
              name: cur.job_status,
              label: cur.customer_name[this.locale],
            });
          }
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    tryAutoStatusAction() {
      if (this.options.statuses.length == 1) {
        this.prepareChange({ status: this.options.statuses[0].name });
      }
    },
    async prepareChange({ status, cancellation }) {
      let changeMixin;
      if (cancellation) {
        let cr = this.options.cancellations.find((cr) => cr.id == cancellation);
        changeMixin = {
          title: cr.description[this.locale],
          confirmLabel: this.$t('confirm'),
          showCancel: true,
          cancellation: cr,
        };
        this.requestMixin = {
          cancellation_reason_id: cancellation,
        };
      } else if (status) {
        changeMixin = {
          status,
          confirmLabel: this.$t('confirm'),
          showCancel: this.options.statuses.length > 1,
        };
        this.requestMixin = {
          new_status: status,
        };
      }
      this.changeResult = null;
      this.change = { loading: { form: true } };

      await axios.post('/api/customer/order/set-status', {
        preview: true,
        job_id: this.order.job.sfid,
        ...this.requestMixin,
      });
      this.change = { ...changeMixin };
    },
  },
};
</script>
