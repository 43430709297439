<template>
  <div>
    <pre v-if="error">{{ error }}</pre>
    <b-spinner v-else />
  </div>
</template>

<script>
import axios from 'axios';
import { JobStatusEnum, UserRoleEnum } from '@gid/models';

import { STORE_ACCESS_TOKEN } from '@gid/vue-common/store/auth.module/types';
const AT_WORK_STATUSES = [
  JobStatusEnum.NEW,
  JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
];

export default {
  props: {
    id: String,
  },
  data() {
    return {
      error: null,
    };
  },
  async created() {
    if (this.id) {
      try {
        const response = await axios.get(`/api/customer/access/${this.id}`);
        const { access_token, order_id, order_api_id } = response.data;

        this.$store.commit(STORE_ACCESS_TOKEN, {
          access_token: access_token,
        });
        const res = await axios.get(`/api/customer/orders/${order_id}`);

        if (
          res.data.job.status == JobStatusEnum.PROPOSAL_APPROVAL_PENDING &&
          res.data.opportunity.can_approve_proposal.includes(
            UserRoleEnum.CUSTOMER,
          )
        ) {
          this.$router.push({
            name: 'quote-accept',
            params: { id: order_id },
          });
        } else if (
          res.data.job.status == JobStatusEnum.CANCELLED &&
          res.data.opportunity.can_approve_proposal.includes(
            UserRoleEnum.CUSTOMER,
          )
        ) {
          this.$router.push({
            name: 'quote-reopen',
            params: { id: order_api_id },
          });
        } else if (AT_WORK_STATUSES.includes(res.data.job.status)) {
          this.$router.push({
            name: 'at-work',
          });
        } else if (res.data.job.status === JobStatusEnum.WAITING_PREPAYMENT) {
          this.$router.push({
            name: 'waiting-prepayment',
            params: {
              orderId: res.data.job.sfid,
            },
          });
        } else if (this.$route.fullPath.includes('/cancel')) {
          this.$router.push({ name: 'order-cancelled', params: { order_id } });
        } else {
          this.$router.push({ name: 'order', params: { id: order_id } });
        }
      } catch (error) {
        this.error = error;
      }
    }
  },
};
</script>
