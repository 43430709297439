<template>
  <div>
    <Header />
    <slot />
    <footer-info />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import FooterInfo from '@/components/FooterInfo.vue';

export default {
  components: {
    Header,
    FooterInfo,
  },
};
</script>

<style lang="scss">
.gid-logo {
  height: 2.4rem;
}
</style>
