import { GET_JOB, ADD_TO_ALTERNATIVES, SET_ALTERNATIVE_IDS } from './types';

export default {
  [GET_JOB](state, payload) {
    state.jobWithAlternatives = payload;
  },
  [ADD_TO_ALTERNATIVES](state, payload) {
    if (!state.alternativeJobs.find((j) => j.sfid == payload.sfid)) {
      state.alternativeJobs = state.alternativeJobs.concat(payload);
    }
  },
  [SET_ALTERNATIVE_IDS](state, payload) {
    state.alternativeJobsIds = payload;
  },
};
