import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  state: {
    alternativeJobsIds: [],
    alternativeJobs: [],
    jobWithAlternatives: null,
  },
  mutations,
  actions,
  getters,
};
