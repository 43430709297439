<template>
  <div>
    <b-jumbotron bg-variant="light">
      <template #header>Wallbox Installation</template>
      <template #lead>{{ title }}</template>
      <hr class="my-4" />
      <template v-if="questions">
        <Question
          v-for="(q, index) in questions"
          :key="index"
          :index="questions_offset + index"
        />
      </template>
      <div>
        <div class="text-center">
          <b-button-group>
            <b-button
              v-if="flow_step > 0"
              class="mr-2"
              variant="primary"
              @click="previousFlowStep()"
            >
              Zurück
            </b-button>
            <b-button
              v-if="answeredAllQuestions && flows.length - 1 > flow_step"
              class="mr-2"
              variant="primary"
              @click="nextFlowStep()"
            >
              Weiter
            </b-button>
          </b-button-group>
        </div>
        <b-button
          v-if="answeredAllQuestions && flows.length - 1 > flow_step"
          @click="nextFlowStep()"
          variant="link"
          class="float-right"
        >
          Überspringen
        </b-button>
      </div>
    </b-jumbotron>
  </div>
</template>

<script>
import Question from './Question.vue';
export default {
  components: {
    Question,
  },
  computed: {
    answeredAllQuestions() {
      // return this.$store.getters.ifQuestionsAnsweredByIndexes( this.questionsIndexes );
      return true;
    },
    flows() {
      return this.$store.getters.getFlowsByOpportunuity;
    },
    questions() {
      return this.$store.getters.questionsByIds(this.questionsIndexes);
    },
    questions_offset() {
      return this.questionsIndexes[0];
    },
  },
  props: {
    title: String,
    questionsIndexes: Array,
    flow_step: Number,
  },
  methods: {
    nextFlowStep() {
      this.$emit('nextFlowStep');
    },
    previousFlowStep() {
      this.$emit('previousFlowStep');
    },
  },
};
</script>
