<template>
  <div>
    <b-container class="my-3" v-if="!approved && !rejected" fluid>
      <strong class="my-5">
        {{ $t('inputs.customer.section.disclaimer') }}
      </strong>
      <div v-if="alternativeToBeDisplayed.length" class="mt-2">
        {{ $t('alternative-quotes-announcement') }}
        <ul v-if="order">
          <li
            v-for="(job, index) in [
              jobWithAlternatives,
              ...alternativeToBeDisplayed,
            ]"
            :key="job"
          >
            <a href="#" @click="switchQuoteTab(job)">
              {{ $t('alternate-quote.alternate') }} {{ index ? index + 1 : '' }}
            </a>
            {{ $t('alternate-quote.estimate') }}
          </li>
        </ul>
      </div>
      <div v-for="(section, index) in computedSections" :key="section">
        <div v-if="section === 'order'" class="my-5">
          <h4>
            {{ index + 1 }}. {{ $t('inputs.customer.section.your-order') }}
          </h4>
          <p>
            <span
              v-html="
                $t('inputs.customer.proposal-date', {
                  proposalDate: `<strong>${proposalDate(true)}</strong>`,
                })
              "
            ></span>
            <span
              v-if="!isGardenaFranceSpecific"
              v-html="
                $t('inputs.customer.order-approval-status', {
                  validityDate: `<strong>${approvalDate}</strong>`,
                })
              "
            ></span>
          </p>
          <b-table-simple
            v-e2e:customerServiceTable
            stacked="md"
            striped
            class="mb-0"
          >
            <b-thead>
              <b-tr>
                <b-th>
                  {{ $t('inputs.customer.table.service') }}
                </b-th>
                <b-th class="text-right">
                  {{ $t('inputs.customer.table.quantity') }}
                </b-th>
                <b-th class="text-right">
                  {{ $t('inputs.customer.table.unit_price') }}
                </b-th>
                <b-th class="text-right">
                  {{ $t('inputs.customer.table.total') }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="service in shownServices" :key="service.sfid">
                <component
                  :is="shouldBeBolded(service)"
                  :stacked-heading="$t('inputs.customer.table.service')"
                >
                  <span v-html="serviceDescription(service)"></span>
                  <b-icon-caret-down-fill
                    v-if="!displayHints"
                    @click="toggleDisplayHints"
                  />
                  <b-icon-caret-up-fill
                    v-if="displayHints"
                    @click="toggleDisplayHints"
                  />
                  <div v-if="displayHints" class="w-75">
                    <small
                      class="text-muted text-break"
                      v-html="serviceHints(service)"
                    ></small>
                  </div>
                </component>
                <b-td
                  class="text-right"
                  :stacked-heading="$t('inputs.customer.table.quantity')"
                >
                  {{ service.quantity }}
                  <span v-if="service.unit_measure != 'Count'">
                    {{ service.unit_measure }}
                  </span>
                </b-td>
                <b-td
                  class="text-right"
                  :stacked-heading="$t('inputs.customer.table.unit_price')"
                >
                  <span v-if="service.price_lists">
                    {{ service.price_lists | currency }}
                  </span>
                </b-td>
                <b-td
                  class="text-right"
                  :stacked-heading="$t('inputs.customer.table.total')"
                >
                  <span v-if="service.quantity * service.price_lists">
                    {{ (service.quantity * service.price_lists) | currency }}
                  </span>
                  <span v-else>{{ $t('inputs.customer.included') }}</span>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-th colspan="3" class="text-right">
                  {{ $t('inputs.customer.order-total-no-vat') }}:
                </b-th>
                <b-td class="text-right">
                  <span>{{ totalNoVat | currency }}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th colspan="3" class="text-right">
                  {{ $t('inputs.customer.order-vat') }} ({{ vatRateRaw }} %):
                </b-th>
                <b-td class="text-right">
                  {{ calculateVat | currency }}
                </b-td>
              </b-tr>

              <b-tr>
                <b-th colspan="3" class="text-right">
                  {{ $t('inputs.customer.order-total') }}:
                </b-th>
                <b-td class="text-right">
                  <strong>{{ totalWithVat | currency }}</strong>
                </b-td>
              </b-tr>
              <template v-if="hasCustomerSubsidy && order.is_installation">
                <b-tr v-if="customerSubsidy">
                  <b-td colspan="3" class="text-right">
                    {{ $t('inputs.customer.subsidy') }}:
                  </b-td>
                  <b-td class="text-right">
                    {{ customerSubsidy | currency }}
                  </b-td>
                </b-tr>
                <b-tr v-if="customerSubsidy">
                  <b-td colspan="3" class="text-right">
                    {{ $t('inputs.customer.order-total-subsidy') }}:
                    <br />
                    {{ $t('inputs.customer.order-total-subsidy-note') }}
                  </b-td>
                  <b-td class="text-right">
                    {{ totalWithVatAndCustomerSubsidy | currency }}
                  </b-td>
                </b-tr>
              </template>
            </b-tfoot>
          </b-table-simple>
          <b-table-simple class="d-md-none">
            <b-tbody>
              <b-tr>
                <b-th class="text-right">
                  {{ $t('inputs.customer.order-vat') }} ({{ vatRateRaw }} %):
                </b-th>
                <b-td class="text-right">
                  {{ calculateVat | currency }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">
                  {{ $t('inputs.customer.order-total') }}:
                </b-th>
                <b-td class="text-right">
                  <strong>{{ totalWithVat | currency }}</strong>
                </b-td>
              </b-tr>
              <template v-if="hasCustomerSubsidy && order.is_installation">
                <b-tr v-if="customerSubsidy">
                  <b-td class="text-right">
                    {{ $t('inputs.customer.subsidy') }}:
                  </b-td>
                  <b-td class="text-right">
                    {{ customerSubsidy | currency }}
                  </b-td>
                </b-tr>
                <b-tr v-if="customerSubsidy">
                  <b-td class="text-right">
                    {{ $t('inputs.customer.order-total-subsidy') }}:
                    <br />
                    {{ $t('inputs.customer.order-total-subsidy-note') }}
                  </b-td>
                  <b-td class="text-right">
                    {{ totalWithVatAndCustomerSubsidy | currency }}
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>

          <template
            v-if="
              hasCustomerSubsidy && customerSubsidy && order.is_installation
            "
          >
            <div class="my-3">
              <p><strong>*KfW Zuschuss</strong></p>
              <p>
                Seit 24. November gibt es dafür ein nationales Programm von der
                KfW. Für eine Wallbox-Installation zuhause kann man dann 900 €
                Zuschuss bekommen. Erst Förderung beantragen und dann die
                Wallbox inklusive Installation bei uns bestellen.
              </p>
            </div>
          </template>
          <div
            class="my-3"
            v-if="order && productSupplierCase && !order.is_hardware_sale"
          >
            <h4>{{ $t('inputs.customer.section.order-product') }}</h4>
            <table class="w-100">
              <tr v-for="product in products" :key="product.id">
                <td>{{ product.quantity }} x {{ product.name }}</td>
              </tr>
            </table>
          </div>
          <div
            class="my-3"
            v-if="order && order.job && order.job.professional_comment"
          >
            <h4>{{ $t('inputs.headings.professional_comment') }}</h4>
            <div style="white-space: pre-line">
              {{ order.job.professional_comment }}
            </div>
          </div>

          <p class="my-3" v-if="order && !order.is_hardware_sale">
            {{ $t('inputs.customer.section.services-warning') }}
          </p>
          <div class="my-3" v-if="order && order.job && isCompleoSupplied">
            Mit der Annahme dieses Angebots bestätigen Sie, dass Sie darüber in
            Kenntnis gesetzt wurden, dass wir
            <strong>keinen Lieferzeitpunkt</strong>
            für die
            <strong>Compleo Wallbox</strong>
            nennen können.
          </div>
          <info-collapse v-if="isDKVOpp" class="my-5">
            <template #header>
              <h4>Arbeitgeber Hausstromrückerstattung</h4>
            </template>
            <template #subheader>
              <p class="m-0">
                Das Ziel und Ergebnis unserer Installation ist lediglich die
                Vorbereitung der letztendlichen Inbetriebnahme zur
                Hausstromrückerstattung über die Systeme des DKV und Ihres
                Arbeitgebers.
              </p>
            </template>
            <template #body>
              <p class="m-0 p-3">
                Die Aktivierung der Hausstromrückerstattung wird und kann erst
                im Nachgang auf die Installation bei Ihnen erfolgen und ist
                nicht direkt durch diese gegeben.
              </p>
              <p class="m-0 p-3">
                Bitte haben Sie Verständnis dafür, dass unsere Partner und auch
                wir dieses Ergebnis nicht direkt am Tag der Installation
                herbeiführen können. Dies ist erst in Kooperation mit den
                Betreibern der beteiligten Systeme im Nachgang möglich und kann
                noch einige wenige Tage zusätzlich in Anspruch nehmen.
              </p>
              <p class="m-0 p-3">
                Um diesen Prozess nicht zu behindern oder zu verlangsamen,
                lassen Sie die frisch installierte Wallbox bitte am Strom und
                ändern Sie die Konfiguration nicht, da ansonsten der Zugriff zur
                notwendigen DKV Konfiguration in Mitleidenschaft gezogen werden
                kann.
              </p>
              <p class="m-0 p-3">
                Sie bekommen eine Abschlussmeldung per E Mail sobald die
                Konfiguration Ihrer Wallbox dann abgeschlossen ist und werden
                daraufhin mit Ihrer DKV +charge Karte auf Kosten Ihres
                Arbeitgebers laden können.
              </p>
              <p class="m-0 p-3">
                Sollte diese Nachricht mehr als wenige Tage nach Installation
                auf sich warten lassen, nehmen Sie bitte mit Ihrem
                Ansprechpartner beim Fuhrparkmanagement Ihres Unternehmens oder
                beim DKV Kontakt auf.
              </p>
              <p class="m-0 p-3">
                Voraussetzung für eine schnelle Durchführung ist die Bestellung
                der Hausstromrückerstattung durch Sie beim DKV, ohne diese sind
                wir nicht in der Lage eine DKV Wallbox für Sie vom DKV
                abzurufen.
              </p>
            </template>
          </info-collapse>
          <info-collapse v-if="wallboxVDE" class="my-5">
            <template #header>
              <h4>Unser Qualitätsversprechen</h4>
            </template>
            <template #subheader>
              <p class="m-0">
                Unsere Handwerkspartner arbeiten nach den aktuell gültigen
                Vorgaben und Vorschriften, maßgeblich
                <strong>VDE AR 4100</strong>
                mit seit 2019 verbindlichen Vorgaben für Zähleranlagen und
                Verteiler, nach der
                <strong>TAB 2019</strong>
                der Netzbetreiber sowie der
                <strong>DIN 18015</strong>
                für die Kabelverlegung.
              </p>
            </template>
            <template #body>
              <p class="m-0 p-3">
                Wir wissen, dass aufgrund der Aktualität sowie der kurzen Zeit
                seitdem das Thema Elektromobilität im Massenmarkt angekommen
                ist, diese Vorgaben nicht allseits bekannt sind und
                berücksichtigt werden, weshalb sich teils eklatante
                Preisunterschiede bei Vergleichsangeboten ergeben. Vielen
                Elektrikern sind die neuen Vorgaben und Vorschriften nicht
                bekannt. Die meisten unserer Handwerkspartner sind Zertifizierte
                Fachbetriebe für E-Mobilität und haben an entsprechenden
                Schulungen des ZVEH teilgenommen.
              </p>
              <p class="m-0 p-3">
                Wir wollen darauf hinweisen, dass die neuen Vorgaben Ihrer
                Sicherheit und zur Verhinderung von möglichen Schäden dienen.
                Durch die mit Ihrer Wallbox gegebene Dauerladelast und
                Erkenntnisse der vermehrten Stromversorgung durch erneuerbare
                Energiequellen sind in den letzten Jahren die Vorschriften
                mehrfach angepasst worden.
              </p>
              <p class="m-0 p-3">
                Mehrkosten durch diese Regularien sind eher auf lange
                Investitionsstaus bei den Verteileranlagen in deutschen
                Haushalten, als auf die Wallbox Installation zurückzuführen. Die
                Investition sichert Ihre Hausanlage jedoch auch für die Zukunft
                ab, unabhängig von der Wallbox. Leider sind viele ältere
                Elektroanlagen nicht für den Anschluss der Dauerladelast einer
                WallBox tauglich und müssen entsprechend der neuen Vorgaben
                ertüchtigt werden.
              </p>
              <p class="m-0 p-3">
                Sollten Sie hierzu Fragen haben, kommen Sie gerne auf uns oder
                unseren Handwerkspartner zu.
              </p>
            </template>
          </info-collapse>
        </div>
        <template v-if="section === 'shippingAddress'" class="my-5">
          <h4>
            {{ index + 1 }}.
            {{
              $t(
                order && order.is_hardware_sale
                  ? 'inputs.customer.section.delivery-address'
                  : 'inputs.customer.section.installation-address',
              )
            }}
          </h4>
          <div v-if="order && order.customer" class="gid-contact-form">
            <b-row class="my-2">
              <b-col md="12">
                <strong>{{ $t('company-name') }}</strong>
                :
                <span>{{ order.customer.company_name }}</span>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col md="12">
                <strong>{{ $t('inputs.customer.name') }}</strong>
                :
                <span v-if="!isGardenaFranceSpecific">
                  {{ order.customer.salutation }}
                </span>
                {{ order.customer.name }}
              </b-col>
            </b-row>
            <quote-address
              :addressFieldsKeys="addressFieldsKeys"
              :address-data="addressData"
              v-model="addressData"
            />
            <br />
            <div v-for="(value, key) in contactData" :key="key">
              <strong>
                {{ $t(`inputs.customer.${key}`) }}
              </strong>
              :
              <validating-group
                :validation-context="validationContext"
                :validation-path="`${validationPath}.phone`"
                :rules="{ phone: true }"
                v-slot="{ clearErrors, state }"
                class="col-lg-4 col-12 mx-0 my-2 p-0"
                v-if="key == 'phone' && !value"
              >
                <b-input-group>
                  <template #prepend>
                    <b-dropdown variant="outline-primary" toggle-class="py-0">
                      <template #button-content>
                        <span class="dialing-country__selected">
                          <country-flag
                            :country="countryPhone.code"
                            class="dialing-country__selected__flag"
                          />
                          <span class="dialing-country__selected__prefix">
                            ( {{ countryPhone.dialing }} )
                          </span>
                        </span>
                      </template>
                      <b-dropdown-item
                        @click="selectCountryPhone(country)"
                        v-for="country in countriesTier1"
                        :key="country.name"
                        :value="country.code"
                      >
                        <div>
                          <country-flag :country="country.code" />
                          <span class="ml-2">
                            {{ country.name }} ( {{ country.dialing }} )
                          </span>
                        </div>
                      </b-dropdown-item>

                      <b-dropdown-divider></b-dropdown-divider>

                      <b-dropdown-item
                        @click="selectCountryPhone(country)"
                        v-for="country in countriesTier2"
                        :key="country.name"
                        :value="country.code"
                      >
                        <div>
                          <country-flag :country="country.code" />
                          <span class="ml-2">
                            {{ country.name }} ( {{ country.dialing }} )
                          </span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <b-form-input
                    v-e2e:customerContactPhone
                    v-model="modelPhone"
                    @input="clearErrors()"
                    :readonly="readonly"
                    :state="state"
                  />
                </b-input-group>
              </validating-group>
              <span v-else>{{ value }}</span>
            </div>
          </div>
          <b-checkbox v-model="shouldSetBillingAddress" class="my-3">
            {{ $t('inputs.customer.section.billing-address') }}
          </b-checkbox>
        </template>
        <div v-if="section === 'billingAddress'" class="my-5">
          <div class="billing-address" v-if="shouldSetBillingAddress">
            <h4>
              {{ index + 1 }}.
              {{ $t('inputs.customer.section.billing-address-customer') }}
            </h4>
            <b-row>
              <b-col>
                <b-form-input
                  :placeholder="
                    $t('inputs.customer.section.billing-first-name')
                  "
                  v-model="order.customer.billing_first_name"
                  class="my-2"
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-input
                  :placeholder="$t('inputs.customer.section.billing-last-name')"
                  v-model="order.customer.billing_last_name"
                  class="my-2"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-form-input
              :placeholder="$t('inputs.customer.section.billing-company-name')"
              v-model="order.customer.billing_company_name"
              class="my-2"
            ></b-form-input>
            <quote-address
              :addressFieldsKeys="addressFieldsKeys"
              :address-data="billingAddressData"
              v-model="billingAddressData"
            />
          </div>
        </div>
        <div v-if="section === 'appointment'" class="my-5">
          <template v-if="order.is_hardware_sale">
            <h4>
              {{ index + 1 }}. {{ $t('inputs.customer.section.shipping') }}
            </h4>
            <p>{{ $t('inputs.customer.section.shipping-text') }}</p>
          </template>
          <template v-else>
            <h4>
              {{ index + 1 }}. {{ $t('inputs.customer.section.appointment') }}
            </h4>
            <p>{{ $t('inputs.customer.section.appointment-text') }}</p>
          </template>
        </div>
        <div v-if="section === 'payment'" class="my-5">
          <h4>
            {{ index + 1 }}. {{ $t('inputs.customer.section.payment-method') }}
          </h4>
          <div
            v-e2e:paymentMethod="paymentMethod"
            v-for="paymentMethod in paymentMethods"
            :key="paymentMethod"
          >
            <b-form-radio
              v-model="selectedPaymentMethod"
              :value="paymentMethod"
              class="my-2"
            >
              {{ $t(`inputs.customer.${paymentMethod}`) }}
              <template v-if="paymentMethod == 'prepayment'">
                <img
                  height="25"
                  class="mr-2"
                  src="../assets/creditcard-logos.png"
                  alt="Mit Stripe Giropay zahlen"
                />
                <img
                  height="25"
                  src="../assets/giropay-logo.png"
                  alt="Mit Stripe Giropay zahlen"
                />
              </template>
            </b-form-radio>
          </div>
          <p v-if="selectedPaymentMethod == 'invoice'" class="mt-2">
            <template v-if="isChargemondoOpp && order.is_hardware_sale">
              {{ $t('chargemondo.invoice-info-hardware-sale') }}
            </template>
            <template v-else-if="isChargemondoOpp">
              {{ $t('chargemondo.invoice-info') }}
            </template>
            <template v-else-if="isEnwegoOpp">
              {{ $t('enwego.invoice-info') }}
            </template>
            <template v-else-if="isBaywaDienstwagenfahrerOpp">
              {{ $t('baywa-dienstwagenfahrer.invoice-info') }}
            </template>
            <template v-else-if="order.is_hardware_sale">
              {{ $t('inputs.customer.invoice-info-hardware-sale') }}
            </template>
          </p>
          <p class="mt-2">
            {{
              $t(
                isXB2CJobType
                  ? 'inputs.customer.installation-additional-info'
                  : 'inputs.customer.invoice-info',
              )
            }}
          </p>
        </div>
      </div>
      <validating-group
        :validation-context="validation_context"
        :rules="{
          required: {
            allowFalse: false,
          },
        }"
        :custom-messages="{
          required: $t('accept-job-requirements-error'),
        }"
      >
        <b-form-checkbox v-e2e:acceptTC v-model="form.accept_job_requirements">
          <i18n path="accept-job-requirements-text" tag="span">
            <template #jobnumber>
              <span v-if="order && order.job" class="text-primary">
                {{ order.job.name }}
              </span>
            </template>
          </i18n>
        </b-form-checkbox>
      </validating-group>
      <validating-group
        :validation-label="$t('accept-tos')"
        :validation-context="validation_context"
        :validation-path="`tos`"
        :rules="{ required: { allowFalse: false } }"
        :custom-messages="{
          required: $t('accept-tos-error'),
        }"
      >
        <i18n
          v-if="isChargemondoOpp || isBaywaOpp"
          path="accept-tos-text-addition-chargemondo"
          tag="p"
        ></i18n>

        <b-form-checkbox
          v-if="isBaywaDienstwagenfahrerOpp"
          v-e2e:acceptTCBaywaDienstwagenfahrer
          v-model="form.accept_tos_baywa_dienstwagenfahrer"
        >
          <i18n
            path="accept-tos-text-addition-baywa-dienstwagenfahrer"
            tag="span"
          ></i18n>
        </b-form-checkbox>

        <b-form-checkbox
          v-if="isBaywaDienstwagenfahrerOpp"
          v-model="form.flat_tax_baywa_dienstwagenfahrer"
        >
          <i18n path="flat-tax-text-baywa-dienstwagenfahrer" tag="span">
            <template #link>
              <a
                href="https://www.groupnet.baywa.com/sites/CB_Upload/cb_veroeffentlichterichtlinien/Documents/Dienstwagenordnung%20der%20BayWa%20AG_ID1447.pdf"
                target="_blank"
              >
                {{ $t('dienstwagenordnung') }}
              </a>
            </template>
          </i18n>
        </b-form-checkbox>

        <b-form-checkbox v-e2e:acceptTC v-model="form.accept_tos">
          <i18n path="accept-tos-text" tag="span">
            <template #link>
              <a
                href="https://www.getitdone.rocks/agb-shopkunden/"
                target="_blank"
              >
                {{ $t('accept-tos') }}
              </a>
            </template>
          </i18n>
        </b-form-checkbox>

        <p v-if="isBaywaDienstwagenfahrerOpp" class="text-danger mt-3 mb-0">
          {{ $t('print-offer-baywa-dienstwagenfahrer') }}
        </p>
      </validating-group>
      <div class="my-4">
        <b-button
          v-e2e:sendApproval
          :disabled="acceptBtnIsDisabled"
          variant="primary"
          size="lg"
          class="mr-2 mt-2 gid-submit-button"
          @click="sendApproval"
        >
          <b-spinner
            v-if="loading"
            class="gid-spinner--button mr-2"
          ></b-spinner>
          {{ $t('inputs.customer.confirm-order') }}
        </b-button>
        <b-button
          :disabled="loading"
          variant="outline-danger"
          size="lg"
          class="mt-2 gid-submit-button"
          @click="sendRejection"
        >
          <b-spinner
            v-if="loading"
            class="gid-spinner--button mr-2"
          ></b-spinner>
          {{ $t('inputs.customer.reject-order') }}
        </b-button>
      </div>
      <b-button
        pill
        variant="outline-secondary"
        @click="printPage"
        size="sm"
        class="px-3"
      >
        <i class="material-icons align-middle mr-2 size-lg">print</i>
        {{ $t('inputs.customer.print') }}
      </b-button>
    </b-container>
    <b-alert show variant="success" class="my-5" fluid v-else-if="approved">
      <h4 class="my-5 text-center">
        {{
          $t(
            order.is_hardware_sale
              ? 'inputs.customer.success.congratulations-hardware-sale'
              : 'inputs.customer.success.congratulations',
          )
        }}
      </h4>
      <b-row>
        <b-col v-if="order && order.job" class="border-right border-dark">
          <strong>{{ $t('inputs.customer.success.order-number') }}:</strong>
          <p>{{ order.job.name }}</p>
        </b-col>
        <b-col class="border-right border-dark">
          <strong>{{ $t('inputs.customer.success.date') }}:</strong>
          <p>{{ Date.now() | moment('L') }}</p>
        </b-col>
        <b-col class="border-right border-dark">
          <strong>{{ $t('inputs.customer.success.total') }}:</strong>
          <p>
            {{ totalWithVat | currency }}
            {{ $t('inputs.customer.success.including-vat') }}
          </p>
        </b-col>
        <b-col>
          <strong>{{ $t('inputs.customer.section.payment-method') }}:</strong>
          <p>{{ $t('inputs.customer.invoice') }}</p>
        </b-col>
      </b-row>
    </b-alert>
    <b-container class="my-5" fluid v-else-if="rejected">
      <h4 class="my-5">{{ $t('inputs.customer.reject.message') }}</h4>
      <b-row class="justify-content-center">
        <b-col
          v-if="order && order.job"
          class="border-right border-dark text-center"
        >
          <strong>{{ $t('inputs.customer.success.order-number') }}:</strong>
          <p>{{ order.job.name }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { CUSTOMER_DETAILS } from '@gid/vue-common/components/inputs/_types';
import { InvoicingTargetEnum, JobStatusEnum, UserRoleEnum } from '@gid/models';
import {
  PrepaymentTypesEnum,
  BusinessTypeEnum,
} from '@gid/models/dist/entities/opportunity-option';
import { appUrl } from '@gid/vue-common/app-settings/appUrls';
import moment from 'moment';
import { RequestQueryBuilder, CondOperator } from '@dataui/crud-request';
import { isNullOrUndefined } from '@gid/models/dist/transformers/common';
import CountryFlag from 'vue-country-flag';
import BaseInput from '@gid/vue-common/components/inputs/mixins/BaseInput';
import ValidatingInput from '@gid/vue-common/components/mixins/ValidatingInput';
import QuoteAddress from '@/components/QuoteAddress.vue';
import { BIcon, BIconCaretDownFill, BIconCaretUpFill } from 'bootstrap-vue';
import InfoCollapse from '@/components/InfoCollapse';
import { excludePrepaymentServices } from '@gid/vue-common/utils';

export const CHARGEMONDO_OPP = '0060800000uCOScAAO';
export const BAYWA_DIENSTWAGENFAHRER_OPP = '0060800000vv2K6AAI';
export const BAYWA_MEHRPLATZ_OPP = '0060800000ujGsRAAU';
export const ENWEGO_OPP = '006GIDRdGZgmybDY1Q';
export const COMPLEO_SUPPLIER_ID = 112;

export default {
  mixins: [BaseInput, ValidatingInput],
  components: {
    CountryFlag,
    QuoteAddress,
    BIcon,
    BIconCaretDownFill,
    BIconCaretUpFill,
    InfoCollapse,
  },
  props: {
    QuoteAddressid: String,
    orderProp: { type: Object, default: null },
    switchTab: Function,
    tabIndex: Number,
  },
  data() {
    return {
      loading: false,
      displayHints: false,
      validation_context: 'signup',
      order: {
        opportunity: {},
      },
      dates: [],
      options: {
        statuses: [],
        cancellations: [],
      },
      addressFieldsKeys: this.isGardenaFranceSpecific
        ? [
            'street',
            'additionalinfo',
            'city',
            'country',
            'postal_code',
            'country_code',
          ]
        : ['street', 'additionalinfo', 'city', 'postal_code', 'country_code'],
      contactFieldKeys: ['phone', 'email'],
      countries: [
        { value: 'DE', text: 'Germany' },
        { value: 'AT', text: 'Austria' },
      ],
      form: {
        accept_job_requirements: false,
        accept_tos: false,
        accept_tos_baywa_dienstwagenfahrer: false,
        flat_tax_baywa_dienstwagenfahrer: false,
      },
      approved: false,
      rejected: false,
      isEditingAllowed: false,
      appointments:
        this.order && this.order.job.dates.length
          ? this.order.job.dates.map((date) => ({
              start: date.start,
              end: date.end,
            }))
          : [],
      paymentMethods: [],
      selectedPaymentMethod: null,
      customerPhone: null,
      countryPhone: {
        name: this.$t('country.germany'),
        code: 'DE',
        dialing: '+49',
      },
      phoneCountries: [
        { name: this.$t('country.germany'), code: 'DE', dialing: '+49' },
        { name: this.$t('country.austria'), code: 'AT', dialing: '+43' },
        { name: this.$t('country.france'), code: 'FR', dialing: '+33' },
        { name: this.$t('country.gb'), code: 'GB', dialing: '+44' },
        { name: this.$t('country.sweden'), code: 'SE', dialing: '+46' },

        { name: this.$t('country.netherlands'), code: 'NL', dialing: '+31' },
        { name: this.$t('country.belgium'), code: 'BE', dialing: '+32' },
        { name: this.$t('country.switzerland'), code: 'CH', dialing: '+41' },
        { name: this.$t('country.denmark'), code: 'DK', dialing: '+45' },
        { name: this.$t('country.poland'), code: 'PL', dialing: '+48' },
        { name: this.$t('country.luxembourg'), code: 'LU', dialing: '+352' },
        { name: this.$t('country.slovenia'), code: 'SI', dialing: '+386' },
        {
          name: this.$t('country.czech-republic'),
          code: 'CZ',
          dialing: '+420',
        },
      ],
      shouldSetBillingAddress: false,
      quoteSections: ['order', 'shippingAddress', 'appointment', 'payment'],
    };
  },
  computed: {
    ...mapGetters([
      'locale',
      'alternativeToBeDisplayed',
      'jobWithAlternatives',
    ]),
    services() {
      if (!this.order || !this.order.services) {
        return [];
      }

      return this.order.services;
    },
    customServices() {
      if (!this.order || !this.order.custom_services) {
        return [];
      }

      return this.order.custom_services;
    },
    additionalItems() {
      if (!this.order || !this.order.additional_items) {
        return [];
      }

      return this.order.additional_items;
    },
    products() {
      if (!this.order || !this.order.products) {
        return [];
      }

      return this.order.products;
    },
    acceptBtnIsDisabled() {
      return (
        !this.isJobRequirementsTicked ||
        !this.isTosTicked ||
        this.loading ||
        !this.selectedPaymentMethod
      );
    },
    wallboxVDE() {
      return (
        this.order.opportunity?.name?.toLowerCase().includes('wallbox') &&
        this.order.is_installation &&
        this.locale === 'de'
      );
    },
    isDKVOpp() {
      return (
        this.order.opportunity?.name?.startsWith('DKV') &&
        this.order.opportunity?.name?.endsWith(')') &&
        this.locale === 'de'
      );
    },
    isCompleoSupplied() {
      const product_package_product_type_id = 4;
      return this.products.some(
        (p) =>
          p.oem_id === COMPLEO_SUPPLIER_ID &&
          p.product_type_id === product_package_product_type_id,
      );
    },
    serviceHints() {
      return (service) => {
        service.hints?.[this.locale] || service.hints?.de || service.hints_de;
        if (service.hints_de) return service.hints_de;
        if (service.hints && Object.keys(service.hints).length) {
          return service[this.locale] || service.hints.de;
        }
      };
    },
    hasAlternatives() {
      return this.order?.properties?.alternative_jobs?.length;
    },
    shouldSendBillingAddress() {
      return (
        this.billingAddressData.street && this.billingAddressData.postal_code
      );
    },

    computedSections() {
      let sectionsArray = [...this.quoteSections];
      if (this.shouldSetBillingAddress) {
        const index = this.quoteSections.indexOf('appointment');
        sectionsArray = [
          ...this.quoteSections.slice(0, index),
          'billingAddress',
          ...this.quoteSections.slice(index),
        ];
      }
      return sectionsArray;
    },
    modelPhone: {
      get() {
        if (this.customerPhone) {
          return this.customerPhone.replace(this.countryPhone.dialing, '');
        }
        return null;
      },
      set(modelPhone) {
        if (modelPhone) {
          this.customerPhone = `${this.countryPhone.dialing}${modelPhone}`;
        } else {
          this.customerPhone = null;
        }
      },
    },
    countriesTier1() {
      return this.phoneCountries.slice(0, 5);
    },
    countriesTier2() {
      return this.phoneCountries.slice(5, this.phoneCountries.length);
    },
    productSupplierCase() {
      return this.products.find(
        (p) => p.supplier !== 'customer' || p.supplier !== 'already_supplied',
      );
    },
    shouldBeBolded() {
      return (service) => {
        if (service.price_lists) return 'b-th';
        if (service.price_selling_net) return 'b-th';
        return 'b-td';
      };
    },
    isGardenaFranceSpecific() {
      return this.order?.opportunity.sfid === '0061p00000q9ZxLAAU';
    },
    isChargemondoOpp() {
      return this.order?.opportunity.sfid == CHARGEMONDO_OPP;
    },
    isEnwegoOpp() {
      return this.order?.opportunity.sfid == ENWEGO_OPP;
    },
    isBaywaOpp() {
      return [BAYWA_DIENSTWAGENFAHRER_OPP, BAYWA_MEHRPLATZ_OPP].includes(
        this.order?.opportunity.sfid,
      );
    },
    isBaywaDienstwagenfahrerOpp() {
      return this.order?.opportunity.sfid == BAYWA_DIENSTWAGENFAHRER_OPP;
    },
    isBaywaMehrplatzOpp() {
      return this.order?.opportunity.sfid == BAYWA_MEHRPLATZ_OPP;
    },
    isJobRequirementsTicked() {
      let conditions = [this.form.accept_job_requirements];

      return conditions.every((x) => x);
    },
    isTosTicked() {
      let conditions = [this.form.accept_tos];
      if (this.isBaywaDienstwagenfahrerOpp) {
        conditions = [
          ...conditions,
          this.form.accept_tos_baywa_dienstwagenfahrer,
          this.form.flat_tax_baywa_dienstwagenfahrer,
        ];
      }
      return conditions.every((x) => x);
    },
    isBaywaBrand() {
      return this.order?.brand?.id == '0010800002uKMYKAA4';
    },
    totalWithVatAndCustomerSubsidy() {
      return this.totalWithVat - this.customerSubsidy;
    },
    totalWithVat() {
      return this.calculateVat + this.totalNoVat;
    },
    totalNoVat() {
      return [
        ...this.services,
        ...this.additionalItems,
        ...this.customServices,
        ...this.productsPaidByCustomer.map((p) => ({
          ...p,
          price_lists_sum: p.price_lists * p.quantity,
        })),
      ].reduce((acc, cur) => acc + cur.price_lists_sum, 0);
    },
    calculateVat() {
      return (
        this.totalNoVat *
        (this.order?.opportunity.currency_tax_config?.vat_rate / 100)
      );
    },
    approvalDate() {
      return moment(this.proposalDate()).add(35, 'days').format('L');
    },
    targetStatus() {
      if (this.selectedPaymentMethod == 'prepayment') {
        return JobStatusEnum.WAITING_PREPAYMENT;
      }
      if (this.order.is_hardware_sale) {
        return JobStatusEnum.ACCEPTED;
      }
      const newStatus =
        this.order?.opportunity.customer_approval_target_status ??
        JobStatusEnum.OPEN;
      return this.order.partner?.id && newStatus === JobStatusEnum.OPEN
        ? JobStatusEnum.ACCEPTED
        : newStatus;
    },
    vatRateRaw() {
      return this.order?.opportunity.currency_tax_config?.vat_rate ?? 19;
    },
    vatRate() {
      return 1 + this.vatRateRaw / 100;
    },
    currencySymbol() {
      return (
        this.order?.opportunity.currency_tax_config?.currency_symbol ?? 'EUR'
      );
    },
    currencyFormatter() {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currencySymbol,
      });
    },
    hasCustomerSubsidy() {
      const blackListProductIds = [314, 1457, 1456, 717, 716];
      return !this.products.some(({ id }) => blackListProductIds.includes(id));
    },
    isInvoicingTargetCustomer() {
      return (
        this.order?.opportunity.invoicing_target ===
        InvoicingTargetEnum.CUSTOMER
      );
    },
    customerSubsidy() {
      return this.order?.opportunity.customer_subsidy ?? 0;
    },
    addressData() {
      let result = {};
      const { shipping_address } = this.order.customer;
      this.addressFieldsKeys.forEach((key) => {
        result[key] = shipping_address[key];
      });

      return result;
    },
    billingAddressData() {
      let result = {};
      const { billing_address } = this.order.customer;
      this.addressFieldsKeys.forEach((key) => {
        if (key == 'country_code') {
          result[key] = this.addressData[key];
        } else {
          result[key] = billing_address[key];
        }
      });
      return result;
    },
    contactData() {
      let result = {};

      this.contactFieldKeys.forEach((key) => {
        result[key] = this.order.customer[key];
      });
      if (!result.phone) {
        result.phone = this.order.customer.mobile_phone;
      }
      return result;
    },
    productsPaidByCustomer() {
      return this.products
        .filter((p) => !isNullOrUndefined(p.price_listing))
        .map((p) => ({
          ...p,
          price_lists: p.price_listing,
        }));
    },
    shownServices() {
      let allServices = [
        ...this.services.filter((s) => !isNullOrUndefined(s.price_lists)),
        ...this.customServices
          .filter(excludePrepaymentServices)
          .filter((s) => !isNullOrUndefined(s.price_lists)),
      ];
      if (
        (this.isBaywaBrand && !this.isBaywaMehrplatzOpp) ||
        !this.displayHints
      ) {
        allServices = allServices.filter((s) => !s.price_lists == 0);
      }
      return [
        ...this.productsPaidByCustomer,
        ...allServices,
        ...this.additionalItems.filter(
          (s) => !isNullOrUndefined(s.price_lists),
        ),
      ];
    },
    isXB2CJobType() {
      return [BusinessTypeEnum.B2C, BusinessTypeEnum.B2B2C].includes(
        this.order?.opportunity?.business_type,
      );
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    proposalDate(format = false) {
      const proposalDate = this.order?.job?.proposal_date
        ? new Date(this.order.job.proposal_date)
        : new Date();

      return format ? moment(proposalDate).format('L') : proposalDate;
    },
    toggleDisplayHints() {
      this.displayHints = !this.displayHints;
    },
    switchQuoteTab(order) {
      if (
        !this.alternativeToBeDisplayed.findIndex((a) => a.sfid == order.sfid) ==
        -1
      ) {
        this.switchTab(0);
      }
      this.switchTab(
        this.alternativeToBeDisplayed.findIndex((a) => a.sfid == order.sfid) +
          1,
      );
    },
    serviceDescription(service) {
      return (
        service.description?.[this.locale] ||
        service.description?.en ||
        service.name ||
        service.description
      );
    },
    printPage() {
      document.execCommand('print');
      return false;
    },
    async loadData() {
      try {
        if (!this.orderProp) {
          this.$store
            .dispatch('ADD_TO_ALTERNATIVES', { id: this.id })
            .then((res) => {
              this.order = this.$store.state.quotes.alternativeJobs?.find(
                (job) => job.api_id == this.id,
              );
            });
        } else {
          this.order = this.orderProp;
        }
        if (this.order.sfid) {
          this.fetchavailableActions();
          this.initPaymentMethods();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchProductOpportunity(productId, opportunityId) {
      const query = {
        filter: [
          {
            field: 'product.id',
            operator: CondOperator.EQUALS,
            value: productId,
          },
          {
            field: 'opportunity.id',
            operator: CondOperator.EQUALS,
            value: opportunityId,
          },
        ],
        join: { field: 'product' },
      };
      const qb = RequestQueryBuilder.create(query).query();
      return axios.get(`/data-api/product-opportunity/?${qb}`);
    },
    async fetchavailableActions() {
      try {
        const response = await axios.post('/api/customer/orders/set-status', {
          preview: true,
          job_id: this.order?.job?.sfid,
        });
        this.options.statuses = response.data.statuses.reduce((acc, cur) => {
          if (cur.allowed) {
            acc.push({
              name: cur.job_status,
              label: cur.customer_name[this.locale],
            });
          }
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async sendApproval() {
      try {
        this.loading = true;
        const {
          salutation,
          first_name,
          last_name,
          eid,
          company_name,
          phone,
          mobile_phone,
          email,
          account_status,
          default_contact,
          email_for_invoices,
          import_id,
          logo,
          name,
          notes,
          id,
          source,
          title,
          shipping_address,
          billing_address,
          billing_company_name,
          billing_first_name,
          billing_last_name,
        } = this.order.customer;
        const customerRequestData = {
          salutation,
          first_name,
          last_name,
          eid,
          company_name,
          phone: this.customerPhone ? this.customerPhone : phone,
          mobile_phone,
          email,
          account_status,
          default_contact,
          email_for_invoices,
          import_id,
          logo,
          name,
          notes,
          id,
          source,
          title,
          billing_company_name,
          billing_first_name,
          billing_last_name,
          shipping_address: {
            ...shipping_address,
            ...this.addressData,
          },
        };
        if (this.shouldSetBillingAddress && this.shouldSendBillingAddress) {
          customerRequestData['billing_address'] = {
            ...billing_address,
            ...this.billingAddressData,
          };
        }

        const { data: updatedOrder } = await axios.post(
          '/api/customer/orders/set-status',
          {
            preview: false,
            new_status: this.targetStatus,
            job_id: this.order.job.sfid,
            inputs: {
              [CUSTOMER_DETAILS]: customerRequestData,
            },
          },
        );
        this.$gtag.event('Accepted', {
          event_category: 'Quote acceptance',
          event_label: 'Accepted',
        });
        if (updatedOrder.job.status == JobStatusEnum.WAITING_PREPAYMENT) {
          let paymentUrl = appUrl(
            'payment',
            `/payment/stripe/for-job/${this.order.job.sfid}`,
          );
          if (updatedOrder.__invoices_generated?.length > 0) {
            const prepaymentInvoice = updatedOrder.__invoices_generated[0];
            paymentUrl += `?documentId=${prepaymentInvoice.id}`;
          }
          window.location.href = paymentUrl;
        }
        this.approved = true;
        this.loading = false;
        const commentData = {
          jobApiId: this.order.job.api_id,
          message: this.$t('quote-approved-comment', {
            date: new Date().toLocaleDateString(),
          }),
          visibleForRoles: [
            UserRoleEnum.ADMIN,
            UserRoleEnum.PARTNER,
            UserRoleEnum.BRAND,
          ],
        };
        await axios.post('/data-api/comment', commentData);
      } catch (e) {
        this.loading = false;
        throw new Error(e);
      }
    },
    async sendRejection() {
      try {
        this.loading = true;
        await axios.post('/api/customer/orders/set-status', {
          preview: false,
          new_status: JobStatusEnum.CLOSED,
          job_id: this.order.job.sfid,
          input: {},
        });
        this.$gtag.event('Rejected', {
          event_category: 'Quote acceptance',
          event_label: 'Rejected',
        });
        this.rejected = true;
        this.loading = false;
        const commentData = {
          jobApiId: this.order.job.api_id,
          message: this.$t('quote-rejected-comment', {
            date: new Date().toLocaleDateString(),
          }),
          visibleForRoles: [
            UserRoleEnum.ADMIN,
            UserRoleEnum.PARTNER,
            UserRoleEnum.BRAND,
          ],
        };
        await axios.post('/data-api/comment', commentData);
      } catch (e) {
        this.loading = false;
        throw new Error(e);
      }
    },
    async toggleEditing() {
      this.isEditingAllowed = !this.isEditingAllowed;
    },
    countryCodeChanged() {
      if (this.addressData.country_code == 'DE') {
        this.addressData.country = 'Germany';
      }
      if (this.addressData.country_code == 'AT') {
        this.addressData.country = 'Austria';
      }
      if (this.billingAddressData.country_code == 'DE') {
        this.billingAddressData.country = 'Germany';
      }
      if (this.billingAddressData.country_code == 'AT') {
        this.billingAddressData.country = 'Austria';
      }
    },
    selectCountryPhone(country) {
      this.countryPhone = country;
      this.customerPhone = '';
    },
    async initPaymentMethods() {
      switch (this.order.opportunity.prepayment) {
        case PrepaymentTypesEnum.REQUIRED:
          this.paymentMethods = ['prepayment'];
          break;
        case PrepaymentTypesEnum.OPTIONAL:
          this.paymentMethods = ['invoice', 'prepayment'];
          break;
        default:
          this.paymentMethods = ['invoice'];
      }
      if (this.order.opportunity.prepayment != PrepaymentTypesEnum.REQUIRED) {
        const { data: isPrepaymentDue } = await axios.get(
          `/documents-api/accounting/prepayments/${this.order.job.sfid}/is-prepayment-due`,
        );
        if (isPrepaymentDue) {
          this.paymentMethods = ['prepayment'];
        }
      }
      if (this.paymentMethods.length == 1) {
        this.selectedPaymentMethod = this.paymentMethods[0];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialing-country__selected__prefix {
  position: relative;
  top: -4.5px;
  left: 4.5px;
  padding-right: 4.5px;
}
</style>
