import {
  QUESTIONS_SET,
  ANSWER_SET,
  FLOWS_SET,
  PLANNING_OPPORTUNITY_SET,
} from './types';

export default {
  [QUESTIONS_SET]: ({ commit }, payload) => {
    commit(QUESTIONS_SET, payload);
  },
  [ANSWER_SET]: ({ commit, getters }, payload) => {
    payload.globalIndex = getters.allQuestions.findIndex((question) => {
      return (
        question.opportunity === getters.opportunity.sfid &&
        question.sort === payload.index
      );
    });
    commit(ANSWER_SET, payload);
  },
  [FLOWS_SET]: ({ commit }, payload) => {
    commit(FLOWS_SET, payload);
  },
  [FLOWS_SET]: ({ commit }, payload) => {
    commit(FLOWS_SET, payload);
  },
  [PLANNING_OPPORTUNITY_SET]: ({ commit }, payload) => {
    commit(PLANNING_OPPORTUNITY_SET, payload);
  },
};
