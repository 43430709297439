<template>
  <div>
    <header-logo
      :chargemondoOpp="isChargemondoOpp"
      :baywaOpp="isBaywaOpp"
      :baywaOppUnique="isBaywaOppUnique"
      :isLoading="loading"
    />
    <div v-if="loading">
      <div class="mb-3">
        <b-skeleton width="65%"></b-skeleton>
        <b-skeleton width="45%"></b-skeleton>
        <b-skeleton width="25%"></b-skeleton>
      </div>
      <div class="mb-3">
        <b-skeleton width="25%"></b-skeleton>
        <b-skeleton width="45%"></b-skeleton>
      </div>
      <b-skeleton-table
        :rows="10"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <div
      v-else-if="alternativeToBeDisplayed.length"
      class="my-3"
      align-v="center"
    >
      <b-tabs
        pills
        align="center"
        nav-wrapper-class="gid-quote-tabs sticky-top bg-light py-2"
        v-model="tabIndex"
      >
        <div v-if="isCompleoWithKebaAlternative">
          <p class="mt-2 p-3">
            Sehr geehrter Kunde,
            <br />
            bei der Überprüfung Ihrer Bestellung ist uns aufgefallen, dass Sie
            eine Wallbox von Compleo bestellt haben. Wir möchten Sie an dieser
            Stelle gerne darauf hinweisen, dass die Produktion dieser Boxen
            herstellerseitig voraussichtlich erst wieder in wenigen Wochen
            aufgenommen wird.
            <br />
            <br />
            Aktuell können wir noch keine Angaben zu einem genauen Lieferdatum
            machen. Um Ihnen den bestmöglichen Service zu bieten und lange
            Wartezeiten zu vermeiden, möchten wir Ihnen hier über das angezeigte
            Alternativangebot die Option anbieten, Ihre bereits beauftragte
            Compleo Wallbox gegen ein Modell von KEBA auszutauschen.
          </p>
        </div>
        <b-tab :title="`${$t('alternate-quote-quote')}`" active>
          <Quote :order-prop="order" :tab-index="0" :switch-tab="switchTab" />
        </b-tab>
        <b-tab
          v-for="(alternative, index) in alternativeToBeDisplayed"
          :key="alternative.sfid"
          :title="`${$t('alternate-quote-quote')} ${index + 2}`"
        >
          <Quote
            :order-prop="alternative"
            :tab-index="index + 1"
            :switch-tab="switchTab"
          />
        </b-tab>
      </b-tabs>
      <router-view />
    </div>
    <div v-else>
      <Quote :order-prop="order" />
    </div>
  </div>
</template>

<script>
import Quote, {
  CHARGEMONDO_OPP,
  BAYWA_DIENSTWAGENFAHRER_OPP,
  BAYWA_MEHRPLATZ_OPP,
  COMPLEO_SUPPLIER_ID,
} from './Quote.vue';
import { mapGetters } from 'vuex';
import HeaderLogo from '@/components/HeaderLogo.vue';

export const KEBA_OEM_ID = 197;
export default {
  components: {
    Quote,
    HeaderLogo,
  },
  props: {
    id: String,
  },
  data: () => ({
    order: null,
    loading: true,
    tabIndex: 0,
  }),
  computed: {
    ...mapGetters(['alternativeToBeDisplayed']),
    isChargemondoOpp() {
      return this.order?.opportunity.sfid == CHARGEMONDO_OPP;
    },
    isBaywaOpp() {
      return BAYWA_DIENSTWAGENFAHRER_OPP == this.order?.opportunity.sfid;
    },
    isBaywaOppUnique() {
      return BAYWA_MEHRPLATZ_OPP == this.order?.opportunity.sfid;
    },
    isCompleoWithKebaAlternative() {
      return (
        (this.order?.products.some((p) => p.oem_id === COMPLEO_SUPPLIER_ID) &&
          this.alternativeToBeDisplayed?.[0]?.products.some(
            (p) => p.oem_id === KEBA_OEM_ID,
          )) ||
        (this.order?.products.some((p) => p.oem_id === KEBA_OEM_ID) &&
          this.alternativeToBeDisplayed?.[0]?.products.some(
            (p) => p.oem_id === COMPLEO_SUPPLIER_ID,
          ))
      );
    },
  },
  methods: {
    async loadOrder() {
      try {
        await this.$store.dispatch('GET_JOB', { id: this.$route.params.id });
        this.order = this.$store.state.quotes.jobWithAlternatives;
        this.loading = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    switchTab(value) {
      this.tabIndex = value;
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>

<style lang="scss">
@media print {
  .gid-quote-tabs {
    display: none;
  }
}
</style>
