import {
  QUESTIONS_SET,
  ANSWER_SET,
  FLOWS_SET,
  PLANNING_OPPORTUNITY_SET,
} from './types';

export default {
  [QUESTIONS_SET](state, payload) {
    state.questions = payload.questions;
  },
  [ANSWER_SET](state, payload) {
    state.questions[payload.globalIndex].answer = payload.answer;
  },
  [FLOWS_SET](state, payload) {
    state.flows = payload.flows;
  },
  [PLANNING_OPPORTUNITY_SET](state, payload) {
    state.opportunity = payload.opportunity;
  },
};
