<template>
  <div>
    <template v-if="questionByIndex.type === 'radio'">
      <b-form-group :label="title">
        <b-form-radio
          v-for="opt in getQuestionOptionsByIndex"
          :key="opt"
          :name="questionByIndex.name"
          :value="opt"
          v-model="answer"
        >
          {{ opt }}
        </b-form-radio>
      </b-form-group>
      <p v-if="questionByIndex.explanation">
        {{ questionByIndex.explanation }}
      </p>
    </template>

    <template v-if="questionByIndex.type === 'file'">
      <b-row class="my-3">
        <b-col lg="100">
          <p>{{ title }}</p>
          <input-files_selector
            v-model="answer"
            :maxFiles="questionByIndex.max"
          >
            {{ $t('upload-planning.add-file') }}
          </input-files_selector>
          <p style="text-align: left" v-if="questionByIndex.explanation">
            {{ questionByIndex.explanation }}
          </p>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import { ANSWER_SET } from '../store/planning.module/types';
export default {
  components: {
    'input-files_selector': FilesSelector,
  },
  computed: {
    answer: {
      get() {
        return this.$store.getters.getAnswer(this.index);
      },
      set(answer) {
        if (answer) {
          this.$store.dispatch(ANSWER_SET, {
            answer: answer,
            index: this.index,
          });
        }
      },
    },
    getQuestionOptionsByIndex() {
      return this.$store.getters.questionByIndex(this.index).options;
    },
    questionByIndex() {
      return this.$store.getters.questionByIndex(this.index);
    },
    title() {
      return `${this.questionByIndex.sort + 1}. ${this.questionByIndex.title}`;
    },
  },
  name: 'question',
  props: {
    index: Number,
  },
};
</script>
